* {
  margin: 0;
}
.homepage-hero {
  min-height: 80vh;
}
.vh {
  min-height: 60vh;
}
/* .bungee-spice-regular {
  font-family: "Bungee Spice", sans-serif!important;
  font-weight: 400!important;
  font-style: normal!important;
} */
/* Navigation */
.body {
  min-height: 100vh;
  /* max-width: fit-content; */
  overflow: hidden;
}
.heading {
  /* margin: 2rem; */
  font-size: 28px;
  font-weight: bold;
  color: #333;
  letter-spacing: 2px;
  /* margin: 0 0 20px; */
  height: 100%;
}
.navbar {
  background-color: #0056d2;
}
.nav-link:hover {
  color: #e74c3c;
}
.nav-link.active {
  color: #e74c3c;
}
.header-login-button {
  background-color: #e74c3c;
  color: #fff!important;
  border-radius: 30px;
  width: 100%;
}
.header-login-button:hover {
  color: #fff!important;
}
.btn-login {
  background-color: #1c1e4f;
  color: #fff;
  border-radius: 30px;
  width: 50%;
  height: 50%;
}
.btn-login:hover {
  transition: 0.5s;
  background-color: #e74c3c;
  color: #fff;
}
.navy {
  color: #1c1e4f;
}
.red {
  color: #e74c3c;
}
.error {
  margin: 0 25%;
}
.plain-button {
  background-color: transparent;
  border: 0;
}
.center {
  display: flex; /* Use flexbox for centering */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}
/* Header CSS */
/* Navbar container */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #333;
  color: white;
  height: 60px;
}
.navbar__logo img {
  height: 40px;
}

.navbar__links {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
}

.navbar__links li {
  margin: 0 15px;
}

.navbar__links a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.navbar__links a:hover {
  text-decoration: underline;
}

.navbar__user {
  display: flex;
  align-items: center;
  padding-right: 10px;
}

.navbar__login, .navbar__profile {
  margin-left: 15px;
  color: white;
  text-decoration: none;
  font-weight: bold;
}

.navbar__login:hover, .navbar__profile:hover {
  text-decoration: underline;
}



/* Mobile Header CSS */
/* Prevent scrolling when menu is open */
.no-scroll {
  overflow: hidden;
}

.mobile-navbar {
  position: relative;
  background-color: #333;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.mobile-navbar__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
}

.mobile-navbar__logo img {
  height: 40px;
}

.mobile-navbar__icon {
  cursor: pointer;
  color: white;
}

.mobile-navbar__menu {
  position: fixed;
  top: 4rem;
  left: 0;
  width: 100%;
  height: 100vh;
  /* background-color: rgba(0, 0, 50, 0.9); */
  background-color: #1c1e4f;
  z-index: 1000; /* Ensure the menu is on top */
  /* display: flex; */
  flex-direction: column;
  justify-content: center;
}

.mobile-navbar__links {
  list-style: none;
  padding: 0;
  /* text-align: center; */
}

.mobile-navbar__links li {
  margin: 15px 0;
  padding: 10px;
  border-bottom: 1px solid grey;
}

.mobile-navbar__links a {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
}

.mobile-navbar__links a:hover {
  text-decoration: underline;
}

/* .mobile-navbar {
  position: relative;
  background-color: #333;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.mobile-navbar__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
}

.mobile-navbar__logo img {
  height: 40px;
}

.mobile-navbar__icon {
  cursor: pointer;
  color: white;
}

.mobile-navbar__menu {
  position: fixed;
  top: 4rem;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 50, 0.9);
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; 
}

.mobile-navbar__links {
  list-style: none;
  padding: 0;
  /* text-align: center;
}

.mobile-navbar__links li {
  margin: 15px 0;
  padding: 10px;
  border-bottom: 1px solid grey;
}

.mobile-navbar__links a {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
}

.mobile-navbar__links a:hover {
  text-decoration: underline;
} */

/* Tournament Header CSS */
.header-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  background-color: goldenrod; /* Match the orange background color */
  color: white;
  height: 80px; /* Adjust based on your design */
}

.header-bar__left {
  flex: 1;
  display: flex;
  flex-direction: column; /* Stack the link and title vertically */
}

.header-bar__link {
  color: white;
  text-decoration: none;
  font-weight: bold;
  font-size: 1rem;
}
.header-bar__link:hover {
  transition: 1s;
  font-size: 1.1rem;
}

.header-bar__title {
  margin: 0;
  font-size: 1.2rem;
  font-weight: bolder;
  margin-top: 3px; /* Space between the link and title */
}

.header-bar__right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.header-bar__right span {
  margin-right: 10px;
}

.header-bar__right img {
  height: 30px; /* Adjust the size of the sponsor logo */
}

/* TOURNAMENT NAVIGATION CSS */
.nav-bar {
  background-color: #1c1e4f;
  padding: 10px 5px;
  /* border-top: 4px solid #ff4500; Match the orange top border */
}

.nav-bar__list {
  display: flex;
  /* Evenly space out the menu items */
  /* justify-content: space-around; */
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.nav-bar__item {
  margin: 0 8px;
}

.nav-bar__link {
  color: white;
  text-decoration: none;
  font-size: 1.1rem;
}

.nav-bar__item--active .nav-bar__link {
  /* Match the yellow color for the active link */
  /* color: #ffc300; */
  font-weight: bold;
  border-bottom: 3px solid #ffc300;

}

/* LEAGUE CSS */
/* LeaguesHeader.css */
.leagues-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 30px;
  border-bottom: 1px solid #d1d1d1; /* Light gray border */
  background-color: #f5f7f9; /* Light background color */
}

.leagues-header__title {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  margin: 0;
}

.leagues-header__buttons {
  display: flex;
  gap: 10px; /* Space between the buttons */
}

.leagues-header__button {
  padding: 8px 15px;
  border: 1px solid #0056d2; /* Blue border color */
  border-radius: 5px;
  background-color: transparent;
  color: #0056d2;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leagues-header__button--join {
  /* Styles specific to "Join a league" button */
}
.leagues-header__button--create .plus-icon {
  margin-right: 5px;
}

.leagues-header__button:hover {
  background-color: #e6f0ff; /* Light blue background on hover */
}

/* MOBILE LEAGUE HEADER CSS */
/* LeaguesHeader.css */
.mobile-leagues-header {
  text-align: center;
  padding: 30px;
  background-color: #f5f7f9; /* Light background color */
  border-bottom: 1px solid #d1d1d1; /* Light gray border */
}

.mobile-leagues-header__title {
  font-size: 2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.mobile-leagues-header__buttons {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Space between the buttons */
}

.mobile-leagues-header__button {
  padding: 10px 15px;
  border: 1px solid #0056d2; /* Blue border color */
  border-radius: 5px;
  background-color: transparent;
  color: #0056d2;
  font-size: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile-leagues-header__button--create .plus-icon {
  margin-right: 5px;
}

.mobile-leagues-header__button:hover {
  background-color: #e6f0ff; /* Light blue background on hover */
}
/* Tournaments */
/* flex wrap */
.flex-container-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  /* font-size: 30px; */
  text-align: center;
  margin: 0;
  width: 100%;
}

.flex-container-wrap > div {
  background-color: transparent;
  margin: 1rem;
}
.flex-container-wrap > .flex-tournament {
  flex: 30%;
  max-width: 30%;
  border: 3px solid #fff;
  border-radius: 5%;
}
.flex-container-wrap > .flex-team {
  flex: 15%;
  max-width: 15%;
  background-color: transparent;
}
.flex-container-wrap > .flex-league {
  flex: 25%;
  max-width: 25%;
}
.flex-container-wrap > .flex-prediction-card {
  flex: 25%;
  max-width: 30%;
  border: 3px solid #fff;
  border-radius: 5%;
}
.flex-container-wrap > .flex-news {
  flex: 20%;
  max-width: 20%;
  /* border: 3px solid #fff; */
  border-radius: 5%;
}
.flex-container-wrap > .flex-team {
  flex: 10%;
  max-width: 10%;
}
.my-component {
  margin: 0 10%;
}
@media (max-width: 1500) {
  .flex-container-wrap > .flex-predciction-card {
  	max-width: 50%!important;
  }
  
}
@media (max-width: 768px) {
  .flex-container-wrap {
    flex-direction: row;
  }
  .flex-container-wrap > .flex-tournament {
  	min-width: 95%!important;
  }
  .flex-container-wrap > .flex-league {
  	min-width: 95%!important;
  }
  .flex-container-wrap > .flex-prediction-card {
  	min-width: 100%!important;
  }
  .flex-container-wrap > .flex-team {
    flex: 30%;
    max-width: 30%!important;
    text-align: center;
  }
}
/* How to play Componet */
.my-column {
  margin: 2px;
  /* background-color: #d19999;  */
  color: rgb(8, 8, 8); /* White text color */
  border-radius: 10px; /* Rounded edges */
  padding: 20px; /* Add some padding for spacing */
  text-align: center; /* Center-align content */
  margin-bottom: 20px; /* Add space between columns */
  box-shadow: 3px 0 0 2px #1c1e4f;
}

/* Style for the buttons */
.my-column button, .start-playing button {
  background-color: #e74c3c; /* White button background */
  color: #fefefe; /* #e74c3c button text color */
  border: none;
  border-radius: 20px; /* Rounded button edges */
  padding: 10px 20px; /* Adjust padding as needed */
  font-weight: bold;
  cursor: pointer;
}
.my-column button:hover, .start-playing button:hover {
  padding: 12px 23px;
  transition: 0.3s;
}
.league-heander-nav-link:hover {
  color: #000;
}
.camel-case {
  text-transform: lowercase;
}

.camel-case::first-letter {
  text-transform: uppercase;
}
.predict-box {
  text-align: center;
  /* width: 50%; */
  border: 1px solid #1c1e4f;
  border-radius: 5px;
}
button.predict-box {
  background-color: #1c1e4f;
  color: #fff;
}
button.predict-box:hover {
  background-color: #1c1e4f;
  color: #fff;
}
.terms-of-use {
  background-color: #e9e9e9;
  margin: 0;
}
@media (max-width: 768px) {
  .flex-container-wrap {
    flex-direction: row;
  }
  .flex-container-wrap > .flex-tournament {
  	min-width: 95%!important;
  }
  .flex-container-wrap > .flex-league {
  	min-width: 95%!important;
  }
  .flex-container-wrap > .flex-team {
    flex: 30%;
    max-width: 30%!important;
    text-align: center;
  }
  .select-team-section {
    margin-left: 3%!important;
  }
  .my-component {
    margin: 0 2%;
  }
}
/* JOIN LEAGUE CSS */
/* League Header Buttons */
.leagues-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.leagues-header__title {
  font-size: 24px;
}

.leagues-header__buttons {
  display: flex;
}

.leagues-header__button {
  margin-left: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

/* Join League Modal */
.join-league-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.join-league-modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.join-league-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close-button {
  background: transparent;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.join-league-input {
  width: 100%;
  padding: 10px;
  margin-top: 20px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.join-league-button {
  width: 100%;
  padding: 10px;
  background: #4285f4;
  color: white;
  border: none;
  border-radius: 4px;
  margin-top: 20px;
  cursor: pointer;
  font-size: 16px;
}
/* NOT FOUND PAGE */

.notfound-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f0f0f5;
  color: navy;
  text-align: center;
}

.notfound-title {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 20px;
}

.notfound-message {
  font-size: 18px;
  margin-bottom: 10px;
}

.notfound-link {
  padding: 10px 20px;
  background-color: #ffc300;
  color: white;
  border-radius: 5px;
  text-decoration: none;
  margin-top: 20px;
  transition: background-color 0.3s;
}

.notfound-link:hover {
  background-color: #ffc300;
}

.notfound-image {
  margin-top: 30px;
  width: 200px;
  height: auto;
}



/* Container */
.verify-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f8f9fa;
  padding: 2rem;
}

/* Content Box */
.verify-content {
  background-color: white;
  border-radius: 8px;
  padding: 3rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 500px;
  width: 100%;
  text-align: center;
}

/* Heading */
.verify-heading {
  color: #1c1e4f; /* Your primary green color */
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

/* Subtext */
.verify-subtext {
  font-size: 1rem;
  color: #333;
  margin-bottom: 2rem;
}

/* Button Styling */
.verify-button {
  width: 100%;
  background-color: #ffc300; /* Green */
  color: white;
  padding: 0.75rem;
  font-size: 1.1rem;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.verify-button:hover {
  background-color: #ffc300; /* Darker green for hover */
}

/* Footer */
.verify-footer {
  margin-top: 2rem;
  font-size: 0.9rem;
  color: #555;
}

/* Footer Link */
.verify-link {
  color: #1c1e4f; /* Green */
  text-decoration: none;
}

.verify-link:hover {
  text-decoration: underline;
}

/* PROFILE PAGE */
.profile-body {
  padding: 2rem;
  max-width: 800px;
  margin: 0 auto;
  text-align: left; /* Aligns text to the left */
}

.profile-header {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.profile-picture {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-right: 1.5rem;
}

.profile-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.profile-info h2 {
  margin: 0;
  font-size: 1.8rem;
  color: #1c1e4f; 
}

.profile-info p {
  font-size: 1rem;
  color: #4A5568;
  margin-top: 0.2rem;
}

.tournaments-section {
  margin-top: 2rem;
}

.tournaments-section h3 {
  color: #1c1e4f;
  margin-bottom: 1rem;
}

.tournaments-section ul {
  list-style: none;
  padding: 0;
  margin: 1px;
}

.tournaments-section li {
  padding: 0.8rem;
  background-color: #ffc300;
  border-radius: 5px;
  margin-bottom: 0.5rem;
}

.tournaments-section li a {
  color: #ffff;
  text-decoration: none;
}

.password-section {
  margin-top: 2rem;
}

.password-section input {
  margin-bottom: 1rem;
  padding: 0.8rem;
  width: 100%;
  border: 1px solid #CBD5E0;
  border-radius: 4px;
  box-sizing: border-box;
}

.delete-account-section {
  margin-top: 2rem;
  text-align: center;
}
.btn-profile {
  background-color: #1c1e4f;
  color: #FFF;
  padding: 0.8rem 2rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
.btn-danger {
  background-color: #e74c3c;
  color: #FFF;
  padding: 0.8rem 2rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
}

/* SIGN UP COMPONENT */